import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import Tabs from "../components/CasesPage/Tabs"
import CardContainer from "../components/CasesPage/CardContainer"
import FooterRocket from "../components/CommonComponents/FooterRocket"
import Banner from "../components/CasesPage/Banner"
import { mainUrl } from "../js/config"
import { dataForCases } from "../information/PortfolioProjects/dataForCases"

export default class CasesPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: "555",
    }
  }

  componentDidMount() {
    let query
    if (window.location.search) {
      query = window.location.search.split("=")[1].split("/")[0]
    }
    if (query) {
      this.setState({
        filter: query,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (window.location.search !== prevProps.location.search) {
      this.setState({
        filter:
          window.location.search !== "" &&
          window.location.search.split("=")[1].split("/")[0],
      })
    }
  }

  render() {
    return (
      <section>
        <SEO
          title="Web and Software Development Cases — OS-System"
          description="Cases of Software Development Projects. Examples of applications created by the OS-System team. How we implement innovative ideas into real modern solutions."
          canonical={`${mainUrl}/cases/`}
        />
        <Layout showFormPoint={100}>
          <Tabs />
          <Banner />
          <CardContainer filter={this.state.filter} projects={dataForCases} />
          <FooterRocket />
        </Layout>
      </section>
    )
  }
}
