import React, { useState, useEffect } from "react"

import { Link } from "gatsby"

import "./Tabs.scss"

export const filterTags = [
  {
    id: 1,
    link: "",
    text: "all",
  },
  {
    id: 2,
    link: "?platform=desktop/",
    text: "desktop",
  },
  {
    id: 3,
    link: "?platform=mobile/",
    text: "mobile",
  },
  {
    id: 4,
    link: "?platform=web/",
    text: "web",
  },
  {
    id: 5,
    link: "?platform=webRTC/",
    text: "webRTC",
  },
  {
    id: 6,
    link: "design/",
    text: "design",
  },
]

export default function Tabs() {
  const [location, setLocation] = useState("All")

  const [offset, setOffset] = useState(-120)

  useEffect(() => {
    setLocation(window.location.search)
    window.addEventListener("scroll", updateScroll)
    return function clean() {
      window.removeEventListener("scroll", updateScroll)
    }
  })

  const updateScroll = () => {
    const scroll = 200
    let currentScroll = window.pageYOffset
    if (scroll > currentScroll) {
      setOffset(-120)
    } else {
      setOffset(0)
    }
  }

  return (
    <div className="tabs-container" style={{ top: `${offset}px` }}>
      <div className="tabs-wrapper">
        <div className="tabs-inner-wrapper">
          {filterTags.map(tab => {
            const { id, link, text } = tab
            return (
              <div
                key={id}
                className={location === link ? "tab active" : "tab"}
              >
                <Link
                  to={"/cases/" + `${link}`}
                  className="tab-link"
                >{`#${text}`}</Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
