import React, {useState, useEffect} from "react"

import {Link} from "gatsby"

import "./Banner.scss"

const filterTags = [
  {
    id: 1,
    link: "/cases/",
    text: "all",
  },
  {
    id: 2,
    link: "/cases/?platform=desktop/",
    text: "desktop",
  },
  {
    id: 3,
    link: "/cases/?platform=mobile/",
    text: "mobile",
  },
  {
    id: 4,
    link: "/cases/?platform=web/",
    text: "web",
  },
  {
    id: 5,
    link: "/cases/?platform=webRTC/",
    text: "WebRTC",
  },
  {
    id: 6,
    link: "/cases/design/",
    text: "design",
  },
]

export default function Banner() {
  const [pageHref,setPageHref] = useState("");

  useEffect(() => {
    setPageHref(location.pathname + location.search);
  });

  const isActive = ({href}) => {
    return pageHref === href && {className: "cases-banner-link  cases-banner-link--active"};
  }

  return (
    <div className="cases-banner-container">
      <div className="cases-banner-wrapper">
        <div className="cases-banner-text-container">
          <h3 className="cases-banner-supheader">
            OS-System - IT Services Provider
          </h3>
          <h1 className="cases-banner-header">Our Cases</h1>
          <div className="cases-banner-link-container">
            {filterTags.map(tag => {
              const {id, link, text} = tag
              return (
                <Link key={id} to={link} className="cases-banner-link"
                      getProps={isActive}>
                  #{text}
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
