import React from "react"

import { Link } from "gatsby"

import Rocket from "./Rocket"

import "./FooterRocket.scss"

export default function FooterRocket() {
  return (
    <div className="footer-rocket-container">
      <div className="footer-rocket-wrapper">
        <div className="footer-rocket-textBlock">
          <h3 className="footer-rocket-title">Already have an idea?</h3>
          <p className="footer-rocket-subtitle">
            Click <Link to="/estimate/">here</Link> <br className="linebreak" />
            to discuss your project!
          </p>
        </div>
        <Rocket />
        <div className="footer-rocket-sky" />
      </div>
    </div>
  )
}
