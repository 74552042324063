import React from "react"

import { Link } from "gatsby"

import Arrow from "../../../images/CaseCards/Icons/Arrow.svg"
import { filterTags } from "../Tabs/Tabs"

import "./CaseCard.scss"

export default function CaseCard({ data }) {
  const { image, title, link, tagBadges, description, classes = "" } = data
  return (
    <Link className={`case-card-container ${classes}`} to={link}>
      <div className="case-card-wrapper">
        <div className="case-card-view">
          <div
            className="case-card-image"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        </div>
        <div className="case-card-blackBg" />
        <div className="case-card-text">
          <h5 className="case-card-title">{title}</h5>
          <div className="case-card-tag-container">
            {tagBadges.map(tag => {
              const { id, text } = tag
              const link = filterTags.find(filter => filter.text === text)?.link
              return (
                <Link to={link} key={id}>
                  <div className="case-card-tag">{`#${text}`}</div>
                </Link>
              )
            })}
          </div>
          <p className="case-card-description">{description}</p>
          <div className="case-card-link-container">
            <p className="case-card-link">learn more</p>
            <img src={Arrow} alt="arrow" className="case-card-link-arrow" />
          </div>
        </div>
      </div>
    </Link>
  )
}
