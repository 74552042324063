import React from "react"

import CaseCard from "../CaseCard"

import "./CardContainer.scss"

export default class CardContainer extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.filter === nextProps.filter) {
      return false
    } else {
      return true
    }
  }

  filterProjects = (data, filter) => {
    if (!filter) {
      return this.mapProjects(data)
    }

    const queries = ["desktop", "web", "mobile", "webRTC"]

    if (!queries.includes(filter)) {
      return this.mapProjects(data)
    }

    let filteredProjects = []
    filteredProjects = data.filter(project => project.tags.includes(filter))

    return this.mapProjects(filteredProjects)
  }

  mapProjects = data => {
    return data.map((item, i) => {
      return <CaseCard key={i} data={item} />
    })
  }

  render() {
    return (
      <div className="cases-cards-container">
        <div className="cases-cards-wrapper">
          {this.filterProjects(this.props.projects, this.props.filter)}
        </div>
      </div>
    )
  }
}
